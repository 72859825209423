import { HydratedRouter } from 'react-router/dom';
import { StrictMode, startTransition } from 'react';
import { getPublicEnvVars } from './lib/env.lib';
import { hydrateRoot } from 'react-dom/client';
import { setupTracker } from '@atreus/tracker';
import { startBrowserErrorTracker } from '@qcx/browser-error-tracker';

const env = getPublicEnvVars();

if (env.PUBLIC_APP_ENV !== 'development') {
  startBrowserErrorTracker({
    driver: 'rollbar',
    appEnv: env.PUBLIC_APP_ENV,
    rollbarAccessToken: env.PUBLIC_ROLLBAR_ACCESS_TOKEN,
    rollbarRelease: env.PUBLIC_APP_VERSION
  });
}

setupTracker({
  amplitudeOptions: {
    environment: env.PUBLIC_APP_ENV as PublicEnvVars['PUBLIC_APP_ENV'],
    client: {
      configuration: {
        serverUrl: env.PUBLIC_AMPLITUDE_URL,
        defaultTracking: false
      }
    }
  },
  sourceProject: 'atreus',
  sourcePlatform: 'folha'
});

startTransition(() => {
  hydrateRoot(
    document,
    <StrictMode>
      <HydratedRouter />
    </StrictMode>
  );
});
